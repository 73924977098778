import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { assign, get } from 'lodash';
import { SchedulerService } from '../../scheduler/scheduler.service';
import { SharedService } from '../shared.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-complete-exam-form',
    templateUrl: './complete-exam-form.component.html',
    styleUrls: ['./complete-exam-form.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatRadioGroup,
        MatRadioButton,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class CompleteExamFormComponent implements OnInit {
	form: FormGroup;
	currentSPS: any;
	technicians: any[];
	films = [0, 1, 2, 3];
	contrasts = ['C-', 'C+'];
	performingPhysicians: any[];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private service: SchedulerService,
		private sharedService: SharedService,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<CompleteExamFormComponent>
	) {
		this.getTechnicians();
		this.getPerformingPhysicians();

		this.form = this.fb.group({
			patientID: '',
			patientName: '',
			reasonForExam: '',
			procedureCode: '',
			performingPhysician: this.fb.group({
				id: ['', Validators.required],
				fullName: '',
			}),
			technician: this.fb.group({
				id: ['', Validators.required],
				fullName: '',
			}),
			contrast: 'C-',
			numberOfFilms: 0,
		});

		this.form.get('patientID').disable();
		this.form.get('patientName').disable();
		this.form.get('reasonForExam').disable();
		this.form.get('procedureCode').disable();
	}

	onSave(value) {
		const sps = assign(this.currentSPS, {
			numberOfFilms: value.numberOfFilms,
			contrast: value.contrast,
			technician: value.technician,
			scheduledPerformingPhysiciansName: value.performingPhysician,
		});

		this.service
			.saveSPS(sps, this.data.isr ? this.data.isr.id : this.data.isrId)
			.subscribe(res => this.dialogRef.close(res));
	}

	ngOnInit() {
		this.service
			.getSPSByISRID(this.data.isr ? this.data.isr.id : this.data.isrId)
			.subscribe(sps => {
				this.currentSPS = sps;

				const formData = {
					patientID: this.data.isr
						? get(this.data.isr, 'patient.externalPatientID', '')
						: get(this.data.item, 'patientID', ''),
					patientName:
						!get(sps, 'workflowItem.confidential') ||
						this.data.canViewConfData
							? this.data.isr
								? get(this.data.isr, 'patient.fullName', '')
								: get(this.data.item, 'patientName', '')
							: '**** ****',
					reasonForExam: get(
						this.data.isr,
						'imagingOrder.reasonForExam.description',
						'***** ****'
					),
					procedureCode: get(sps, 'procedureCode.code'),
					contrast: get(sps, 'contrast', 'C-'),
					numberOfFilms: sps.numberOfFilms,
					technician:
						sps && sps.technician ? sps.technician : { id: '' },
					performingPhysician:
						sps && sps.scheduledPerformingPhysiciansName
							? sps.scheduledPerformingPhysiciansName
							: { id: '' },
				};

				setTimeout(() => this.form.patchValue(formData), 200);
			});
	}

	getTechnicians() {
		this.sharedService
			.getTechnicians()
			.subscribe(res => (this.technicians = res));
	}

	getPerformingPhysicians() {
		this.sharedService
			.getPerformingPhysicians()
			.subscribe(res => (this.performingPhysicians = res));
	}
}
