import {
    Component,
    DestroyRef,
    inject,
    isDevMode,
    OnInit,
} from "@angular/core";
import {SchedulerService} from "../scheduler.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogClose, MatDialogActions } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    AddressDTO,
    AppointmentDTO,
    AVSC,
    DEBITERS,
    DefaultValues,
    ExamDetailDTO,
    GeneralSettingDTO,
    Hl7IdName,
    ImagingExamDTO,
    InsuranceDTO,
    MedicalHistoryDTO,
    PatientDTO,
    PatientFullDTO,
    Payer,
    PaymentDTO,
    PhysicianDTO,
    PostalCode,
    ProcedureCodeDTO,
    ReferringPhysicianDTO,
    TemplateModelDTO,
    ThermalPrintModel,
} from "../../model";
import {
    combineLatest,
    forkJoin,
    Observable,
    of
} from "rxjs";
import {
    FileElement,
    FileService,
    ReferringPhysicianAddComponent,
    ReferringPhysiciansSearchComponent,
    SharedService,
} from "../../shared";
import {SettingService} from "../../setting/setting.service";
import {
    catchError,
    debounceTime,
    first,
    map,
    startWith,
    switchMap,
    tap,
} from "rxjs/operators";
import { MatSelectChange, MatSelect } from "@angular/material/select";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete";
import {
    assign,
    filter as _filter,
    find as _find,
    get,
    set as _set,
    xor,
} from "lodash";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AvailabilityCheckComponent} from "../availability-check/availability-check.component";
import moment from "moment/moment";
import {PathologyEditComponent} from "../../setting/pathology-setting/pathology-edit/pathology-edit.component";
import {AppConfigService} from "../../app-config.service";
import {PatientService} from "../../patient/patient.service";
import {PatientListComponent} from "../../shared/patient-list/patient-list.component";
import { MatSlideToggleChange, MatSlideToggle } from "@angular/material/slide-toggle";
import {month_from_str, StringUtils} from "../../utils";
import {fromFetch} from "rxjs/fetch";
import {ProcedureCodesComponent} from "../procedure-codes/procedure-codes.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { AsyncPipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressBar } from "@angular/material/progress-bar";
import { FileExplorerComponent } from '../../shared';
import { MatTooltip } from "@angular/material/tooltip";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from "@angular/material/datepicker";
import { MatBadge } from "@angular/material/badge";
import { MatOption } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from "@angular/material/expansion";
import { MatIconButton, MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatToolbar } from "@angular/material/toolbar";

type PecLine = { code: string; price: number; grantedPrice: number };

@Component({
    selector: "ft-exam-scheduler",
    templateUrl: "./exam-scheduler.component.html",
    styleUrls: ["./exam-scheduler.component.scss"],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatAccordion,
        MatExpansionPanel,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatExpansionPanelDescription,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatSuffix,
        MatBadge,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatDatepicker,
        MatRadioGroup,
        MatRadioButton,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatTooltip,
        MatButton,
        MatSlideToggle,
        FileExplorerComponent,
        MatProgressBar,
        MatDialogActions,
        TranslateModule,
        AsyncPipe,
    ],
})
export class ExamSchedulerComponent implements OnInit {
    patientForm: FormGroup;
    addressForm: FormGroup;
    insuranceForm: FormGroup;
    medicalHistoryForm: FormGroup;
    paymentForm: FormGroup;
    payerFormControl: FormControl = new FormControl("PATIENT");
    ageForm: FormGroup;
    examsForm: FormGroup;
    private _exams: FormArray;
    private _accessionNumber: any;

    private selectedPecID: string;
    private selectedPecRef: string;

    private pecLines: any[] = [];

    generalSetting: GeneralSettingDTO;

    titles: any = [];
    genders: any = [];
    priorities: any = [];
    confidentialities: any = [];
    debiters: string[] = DEBITERS;
    patientClasses: any = [];
    tariffs: { id: number; name: string }[] = [];
    alerts: any;
    selected: number;
    examSaved: boolean = false;
    saving: boolean = false;
    convType: string = "";
    organismConventions: {
        conventionName: string;
        conventionId: number;
        patientPart: number;
        organismPart: number;
    }[] = [];
    organismPecs: any;

    patientSelected: boolean = false;

    filteredPostalCodes: PostalCode[] = [];
    cityControl = new FormControl("");
    postalCodeControl = new FormControl("");

    aets: any[];
    technicians: any[];
    filteredOrganisms: Observable<any[]> = of([]);
    tariffId: number;
    admissionNumber: string;
    paymentID: string;
    accessionNumbers: string[] = [];
    private exceptions: {
        procedureCode: string;
        patientPart: number;
        organismPart: number;
    }[] = [];

    procedureCodeControl = [new FormControl()];
    referringPhysicianControl = [new FormControl()];
    filteredProcedures: Array<ProcedureCodeDTO[]> = [];
    filteredReferringPhysicians: Array<ReferringPhysicianDTO[]> = [];
    templateModelCtrl = [new FormControl()];
    filteredTemplateModels: Array<TemplateModelDTO[]> = [];
    private tariffLines: any[] = [];
    filteredAets: Array<any[]> = [];
    performingPhysicians: any[];
    currencyFormat: string = "DH";

    private defaultValues: DefaultValues;

    editable: boolean = true;
    public patientID: any;
    banks: any = [];
    paymentItems: any[] = [];
    leftAmount: number = 0;
    paymentMethods: any = [];
    fileElements: Observable<FileElement[]> = of([]);
    currentRoot: FileElement;
    currentPath: string;

    canNavigateUp: boolean = false;

    numOfDuplicatePatients: number = 0;

    private readonly datasets: string =
        "priorities,aets,banks,technicians,performingPhysicians,genders,titles,patientClasses,confidentialities,paymentMethods,generalSetting,defaultValues";
    private _selectedProcedures: ProcedureCodeDTO[] = [];
    private savedIsrIdFromPacs: any;
    public ready: boolean = false;
    private organisms: Hl7IdName[] = [];
    updating: boolean;
    private placerOrderNumber: string;
    private visitNumber: string;

    public data = inject(MAT_DIALOG_DATA);
    public _fb = inject(FormBuilder);
    public _snack = inject(MatSnackBar);
    public _dialog = inject(MatDialog);
    public _shared = inject(SharedService);
    public _patientService = inject(PatientService);
    public _fileService = inject(FileService);
    public _setting = inject(SettingService);
    public _config = inject(AppConfigService);
    public _scheduler = inject(SchedulerService);
    public _dialogRef = inject(MatDialogRef);
    private selectedConventionId: any;

    #destroyRef = inject(DestroyRef);

    constructor() {
        this.#destroyRef.onDestroy(() => (this.saving = false));

        this._setupForms();
        this._checkExistingPatientsCount();
    }

    eFactActivated = () => this._config.eFactActivated;

    private _checkExistingPatientsCount(): void {
        if (!this.data?.examDetails)
            this.patientForm.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap((value: any) => {
                        if (value.firstName && value.lastName)
                            return this._patientService.countExistingPatients(
                                value.firstName,
                                value.lastName
                            );
                        else return of(0);
                    }),
                    takeUntilDestroyed(this.#destroyRef)
                )
                .subscribe((total) => (this.numOfDuplicatePatients = total));
    }

    upperCase(event: any, field: string): void {
        const name = event.target.value;
        this.patientForm.get(field).patchValue(name.toUpperCase());
    }

    capitalize(event1: any, field: string): void {
        const name = event1.target.value;
        this.patientForm
            .get(field)
            .patchValue(name.charAt(0).toUpperCase() + name.substring(1));
    }

    onSelectPEC(pec: MatSelectChange): void {
        this.selectedPecID = this.splitAndGetByIndex(pec.value, 0);
        this.selectedPecRef = this.splitAndGetByIndex(pec.value, 1);

        this.paymentForm.get("payer").patchValue(Payer.THIRD_PAYER);

        this.getPECLines(this.selectedPecID);
    }

    private getPECLines(pecId: any): void {
        this.pecLines = [];
        this._shared
            .getPECLines(pecId)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((data) => {
                this.pecLines = data.map((_line: string): PecLine => {
                    const line = _line.split("@");
                    return {
                        code: line[0],
                        price: parseFloat(line[1]),
                        grantedPrice: parseFloat(line[2]),
                    };
                });

                for (let i = 0; i < this.pecLines.length; i++) {
                    const pecLine = this.pecLines[i];
                    if (pecLine.code) {
                        const fg = this.fillForm(pecLine, i);
                        if (fg) {
                            const patientPart = +(
                                (100 * (pecLine.price - pecLine.grantedPrice)) /
                                pecLine.price
                            ).toFixed(2);
                            setTimeout(() => {
                                fg.patchValue({
                                    patientPart: patientPart,
                                    organismPart: 100 - patientPart,
                                    pecId: this.selectedPecID,
                                    pecRef: this.selectedPecRef,
                                });
                            }, 400);
                        }
                    }
                }
                this.removeItem(0);
            });
    }

    fillForm(pecLine: PecLine, idx: number): FormGroup {
        const fg: FormGroup = this.addItem();

        this._shared
            .getProcedureCodeDTOByCode(pecLine.code)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((value: ProcedureCodeDTO) => {
                if (value) {
                    this.procedureCodeControl[idx].patchValue(value.code);
                    this._selectedProcedures[idx] = value;

                    const patientPart = +(
                        (100 * (pecLine.price - pecLine.grantedPrice)) /
                        pecLine.price
                    ).toFixed(2);
                    fg.patchValue({
                        patientPart,
                        organismPart: 100 - patientPart,
                    });

                    this._selectProcedure(fg, value, idx);
                } else console.log("Code not found !");
            });

        return fg;
    }

    changeTariff(selection: MatSelectChange): void {
        if (selection.value)
            this._shared
                .getTariffLines(selection.value)
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe((data) => {
                    if (data) {
                        this.tariffLines = data.map((it) => {
                            const line = it.split("@");
                            return {
                                code: String(line[0]).trim(),
                                price: Number(line[1]),
                            };
                        });
                    }
                });
    }

    onSelectOrganism(selection: any): void {
        const name = selection.option.value;

        const org = this.organisms.find((it) => it.name.trim() === name.trim());
        if (!org) return;

        this.paymentForm
            .get("organismName")
            .patchValue(org.name, {emitEvent: false});

        this.insuranceForm.patchValue(
            {
                organismName: org.name,
                organismId: org.id,
                code: org.code,
            },
            {emitEvent: false}
        );

        if (this.generalSetting?.billingRequired) {
            if (this.convType === "THIRD_PARTY_PAYMENT")
                this._findConventions(org.id);
            else if (this.convType === "PEC") this._findOrganismPECs(org.id);
        }
    }

    private _findConventions(organismId: any): void {
        this.organismConventions = [];
        this._shared
            .getOrganismConventions(organismId)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((data) => {
                this.organismConventions = data.map((it) => {
                    const arr = it.split("@");
                    return {
                        conventionName: arr[0],
                        conventionId: +arr[1],
                        patientPart: +arr[2],
                        organismPart: +arr[3],
                    };
                });

                if (data.length === 1) {
                    const _convention = this.organismConventions[0];
                    this.insuranceForm.patchValue(_convention);
                }
            });
    }

    private _findOrganismPECs(organismId: any): void {
        this.organismPecs = [];
        this._shared
            .getOrganismPECS(this.patientID, organismId)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((data) => (this.organismPecs = data));
    }

    searchProcedureCode(fg: FormGroup, idx: number): void {
        this._dialog
            .open(ProcedureCodesComponent, {minWidth: "900px"})
            .afterClosed()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((code) => {
                if (code) {
                    this._selectedProcedures[idx] = code;
                    this._selectProcedure(fg, code, idx);
                }
            });
    }

    onChangePostalCode(event: MatAutocompleteSelectedEvent): void {
        const postalCode = event.option.value;

        this.addressForm.get("city").patchValue(postalCode.location);
        this.addressForm.get("postalCode").patchValue(postalCode.code);
        this.cityControl.patchValue(postalCode.location);
        this.postalCodeControl.patchValue(postalCode.code);
    }

    onChangeProcedureCode(event: any, fg: FormGroup, idx: number): void {
        const code = event.option.value;
        this._selectedProcedures[idx] = code;

        const exCode = _find(this.exceptions, {
            procedureCode: code.code.trim(),
        });

        if (exCode && this.convType !== "PEC")
            fg.patchValue({
                patientPart: exCode.patientPart,
                organismPart: exCode.organismPart,
            });

        this._selectProcedure(fg, code, idx);
    }

    private _selectISR(isr: any): void {
        if (isr && isr instanceof AppointmentDTO) {
            const apt: AppointmentDTO = {...isr};

            this.placerOrderNumber = apt.placerOrderNumber;
            this.visitNumber = apt.visitNumber;

            const form = this.examsForm.get("exams")["controls"][0];

            form.patchValue({
                date: apt.startDate,
                time: moment(apt.startTime).format("HH:mm"),
                referringPhysicianId: apt.referringPhysicianId,
                commentsOnTheSPS: apt.comments,
            });
            this._generateAccessionNumber();

            if (apt.referringPhysicianId !== null)
                this._shared
                    .getReferringPhysicianById(apt.referringPhysicianId)
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((doctor) => {
                        if (doctor)
                            this.referringPhysicianControl[0].patchValue(doctor?.fullName);
                    });

            if (apt.appointmentReasonId) {
                this._shared
                    .getProcedureCodeDTO(apt.appointmentReasonId)
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((code) => {
                        if (code) this._selectProcedure(form, code, 0);
                    });
            }
        }
    }

    private _selectProcedure(
        fg: FormGroup,
        procedure: ProcedureCodeDTO,
        idx: number
    ): void {
        fg.patchValue({procedureCodeId: procedure?.id});

        /* If there is a tariff set by the center, get that tariff and use it*/
        const tariff = this.tariffLines.find((it) => it.code === procedure.code);
        fg.get("totalAmount").patchValue(
            tariff ? tariff.price : procedure.billingCodePrice
        );

        const {
            code,
            description,
            templateModelId,
            templateModelName,
            modalityId,
            defaultPerformingPhysicianId,
            reasonForExamId,
        } = procedure;
        this.procedureCodeControl[idx].patchValue(
            code !== description ? code + " - " + description : description
        );

        /* Get the default template model from the procedure code */
        if (!this.templateModelCtrl[idx].value) {
            this.templateModelCtrl[idx].patchValue(templateModelName);
            fg.patchValue({templateModelId});
        }

        /* Get the AETs related to the procedure code modality, If no AET is there display a warning */
        this.filteredAets[idx] = _filter(
            this.aets,
            (ae) => ae.modalityId === modalityId
        );
        if (this.filteredAets[idx].length === 0) {
            this._snack.open("Aucun appareil lié à cet examen", "", {
                duration: 3000,
            });
            return;
        }

        const defaultPhysicianId =
            defaultPerformingPhysicianId ||
            (this.defaultValues.defaultPerformingPhysician
                    ? this.performingPhysicians.find(
                        (it) => it.id === this.defaultValues.defaultPerformingPhysician
                    )
                    : this.performingPhysicians[0]
            )?.id;

        const aet =
            this.filteredAets[idx].find((ae) => ae.setAsDefault) ||
            this.filteredAets[idx][0];

        const technicianId =
            aet.defaultTechnicianId ||
            this.technicians.find(
                (it) => it.id === this.defaultValues.defaultTechnician
            )?.id;

        fg.patchValue({
            aetId: aet?.id,
            technicianId: technicianId,
            performingPhysicianId: defaultPhysicianId,
            reasonForExamId: reasonForExamId,
        });
    }

    onChangeReferringPhysician(event: any, fg: FormGroup, idx: number): void {
        const physician: ReferringPhysicianDTO = event.option.value;
        fg.patchValue({referringPhysicianId: physician.id});
        this.patientForm.patchValue({consultingDoctorId: physician.id});
        this.referringPhysicianControl.forEach((it) =>
            it.patchValue(physician.fullName)
        );

        const formGroups = this.examsForm.get("exams")["controls"] as FormGroup[];
        formGroups.forEach((_fg) => {
            _fg.patchValue({referringPhysicianId: physician.id});
        });
    }

    enterLinearTotalAmount(fg: FormGroup, e: KeyboardEvent): void {
        const total = +e.target["value"];
        const percentage = fg.get("percentageDiscount").value || 0;

        fg.get("discount").patchValue(+((total * percentage) / 100).toFixed(2));
    }

    enterLinearPercentageDiscount(fg: FormGroup, e: KeyboardEvent): void {
        const percentage = +e.target["value"];
        const total = fg.get("totalAmount").value;

        let discount = 0;
        if (total && percentage)
            discount = +((total * percentage) / 100).toFixed(2);

        fg.get("discount").patchValue(discount);
    }

    enterLinearDiscount(fg: FormGroup, e: KeyboardEvent): void {
        const discount = +(e.target["value"] || "0");
        const total = fg.get("totalAmount").value;
        let percentageDiscount = 0;
        if (total && discount)
            percentageDiscount = +((discount * 100) / total).toFixed(2);
        fg.get("percentageDiscount").patchValue(percentageDiscount);
    }

    private _filterOrganisms(): void {
        this.filteredOrganisms = this.insuranceForm
            .get("organismName")
            .valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
            );
    }

    private _filter(value: any): any {
        const filterValue = value ? value.toLowerCase() : "";
        return this.organisms.filter((it) =>
            (it.name + it.code).toLowerCase().includes(filterValue)
        );
    }

    private _filterProcedures(): void {
        this.procedureCodeControl[0].valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._shared.queryProcedureCodes(10, 0, "code", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredProcedures[0] = data));
        this.procedureCodeControl[0].patchValue("");
    }

    private _filterReferringPhysicians(): void {
        this.referringPhysicianControl[0].valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._shared.queryReferringPhysicians(10, 0, "lastName", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredReferringPhysicians[0] = data));
        this.referringPhysicianControl[0].patchValue("");
    }

    private _filterTemplateModels(): void {
        this.templateModelCtrl[0].valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._setting.queryTemplateModels(10, 0, "name", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredTemplateModels[0] = data));
        this.templateModelCtrl[0].patchValue("");
    }

    private _filterCities(): void {
        this.cityControl.valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._shared.getPaginatedPostalCodes(10, 0, "code", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredPostalCodes = data));
        this.cityControl.patchValue("");
    }

    private _filterPostalCodes(): void {
        this.postalCodeControl.valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._shared.getPaginatedPostalCodes(10, 0, "code", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredPostalCodes = data));
        this.postalCodeControl.patchValue("");
    }

    private _verifyAndGenerateIDs(): void {
        if (
            this.generalSetting?.patientIdGenerationMode === "AUTOMATIC" &&
            !(
                get(this.data, "patient.patient.externalPatientID") ||
                get(this.data, "queryParam.patientID") ||
                get(this.data, "examDetails.patient.externalPatientID")
            )
        )
            this._generatePatientID();

        if (
            this.generalSetting?.accessionIdGenerationMode === "AUTOMATIC" &&
            !this.data?.examDetails &&
            !get(this.data, "queryParam.accessionNumber")
        )
            this._generateAccessionNumber();
    }

    ngOnInit(): void {
        this.currencyFormat = this._config.currencyFormat;
        this.updating = !!this.data?.examDetails;
        this.editable = !!this.data?.editable;
        this.admissionNumber = this.data?.admissionNumber;
        this.paymentID = this.data?.paymentID;
        this.accessionNumbers = get(this.data, "accessionNumbers", []);

        this._shared
            .getDatasets(this.datasets)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((data) => {
                this.datasets.split(",").forEach((it) => (this[it] = data[it]));
                this.ready = true;

                this._filterProcedures();
                this._filterReferringPhysicians();
                this._filterTemplateModels();
                this._filterCities();
                this._filterPostalCodes();
                this._filterOrganisms();
                this._verifyAndGenerateIDs();

                this._fillFormFromData();

                this.setDefaultValues();
            });
    }

    private _fillFormFromData(): void {
        if (this.data?.patient) {
            const {patient, medicalHistory} = this.data.patient as PatientFullDTO;

            this.selectPatient(patient);
            this.medicalHistoryForm.patchValue(medicalHistory);
        }
        if (this.data?.isr) this._selectISR(this.data.isr);

        if (this.data?.queryParam)
            this._selectDataFromPacsStudy(this.data.queryParam);

        if (this.data?.examDetails)
            this._selectActualExamDetails(this.data.examDetails);
    }

    private _selectDataFromPacsStudy(queryParam: any): void {
        let isAnExistingPatient = false;
        if (queryParam.patientID) {
            this._patientService
                .getPatientDTOByID(queryParam.patientID)
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe((patient) => {
                    if (patient) {
                        isAnExistingPatient = true;
                        this.selectPatient(patient);
                    } else this._selectQueryPacsData(queryParam);
                });
        } else this._selectQueryPacsData(queryParam);
    }

    private _selectQueryPacsData(queryParam: any): void {
        const {
            patientName,
            patientSex,
            patientID,
            accessionNumber,
            studyInstanceUID,
            studyDate,
            studyTime,
        } = queryParam;
        let _patientName = patientName,
            _firstName = "",
            _lastName = "",
            _genderId: number;

        if (_patientName) {
            _patientName = _patientName.trim().replaceAll("^", "@");
            [_lastName, _firstName] = _patientName
                .split("@")
                .filter((it) => it != "");
        }

        if (patientSex && ["M", "F", "O"].includes(patientSex.toUpperCase())) {
            let sex = patientSex;
            if (sex === "O") sex = "U";
            _genderId = this.genders.find((g) => g.value === sex)?.id;
        } else _genderId = this.genders.find((g) => g.value === "U")?.id;

        this.patientForm.patchValue({
            firstName: _firstName ?? "Noname",
            lastName: _lastName ?? "Noname",
            genderId: _genderId,
            externalPatientID: patientID?.replaceAll("/", "")?.replaceAll(" ", ""),
        });

        const form = this.examsForm.get("exams")["controls"][0];

        form.patchValue({
            accessionNumber: accessionNumber,
            studyInstanceUID: studyInstanceUID,
            date: moment(studyDate).utc(true),
            time: studyTime
                ? moment(queryParam.studyTime.substring(0, 6), "HHmmss")
                    .utc(true)
                    .format("HH:mm")
                : moment().format("HH:mm"),
        });

        this._shared
            .findProcedureCodeDTO(
                queryParam.studyDescription,
                queryParam.modalitiesInStudy
            )
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((res) => {
                if (res) this._selectProcedure(form, res, 0);
            });
    }

    private _generatePatientID(): void {
        this._shared
            .generatePatientId()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((res) => {
                this.patientForm.get("externalPatientID").patchValue(res.id);
                this.patientID = res.id;
            });
    }

    private _generateAccessionNumber(): void {
        this._shared
            .generateAccessionNumber()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((res) => {
                this._accessionNumber = res.id;

                const _exams = this.examsForm.get("exams");
                const _controls = _exams["controls"];

                _controls[0].get("accessionNumber").patchValue(res.id);
            });
    }

    addReferringPhysician(fg: FormGroup, idx: number): void {
        this._dialog
            .open(ReferringPhysicianAddComponent)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((physician: ReferringPhysicianDTO) => {
                if (physician) {
                    this.filteredReferringPhysicians[idx].push(physician);
                    fg.patchValue({referringPhysicianId: physician?.id});
                    this.patientForm.patchValue({
                        consultingDoctorId: physician?.id,
                    });
                    this.referringPhysicianControl.forEach((it) =>
                        it.patchValue(physician.fullName)
                    );
                }
            });
    }

    resetReferringPhysician(fg: FormGroup): void {
        fg.patchValue({referringPhysicianId: null});
        this.patientForm.patchValue({
            consultingDoctorId: null,
        });
        this.referringPhysicianControl.forEach((it) => it.patchValue(""));
    }

    findReferringPhysician(item: any, i: number): void {
        this._dialog
            .open(ReferringPhysiciansSearchComponent, {
                minWidth: "600px",
                minHeight: "60vh",
                disableClose: true,
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((physician) => {
                if (physician) {
                    this.filteredReferringPhysicians[i].push(physician);
                    item.patchValue({referringPhysician: physician});
                    this.patientForm.patchValue({
                        consultingDoctorId: physician?.id,
                    });
                    this.referringPhysicianControl.forEach((it) =>
                        it.patchValue(physician.fullName)
                    );

                    const formGroups = this.examsForm.get("exams")[
                        "controls"
                        ] as FormGroup[];
                    formGroups.forEach((_fg) => {
                        _fg.patchValue({referringPhysicianId: physician?.id});
                    });
                }
            });
    }

    checkAvailability(fg: FormGroup): void {
        const value = fg.getRawValue();

        const dialogRef = this._dialog.open(AvailabilityCheckComponent, {
            data: true,
        });
        dialogRef.componentInstance.avsc = new AVSC(
            get(value, "technicianId"),
            get(value, "aetId"),
            get(value, "date"),
            get(value, "time"),
            get(value, "duration", 15)
        );

        dialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((val) => {
                if (val)
                    fg.patchValue({
                        date: new Date(moment(val.date).format()),
                        time: val.start,
                    });
            });
    }

    onChangeTemplateModel(event: any, fg: FormGroup, idx: number): void {
        const templateModel = event.option.value;
        fg.patchValue({templateModelId: templateModel?.id});
        this.templateModelCtrl[idx].patchValue(templateModel.name);
    }

    splitAndGetByIndex = (str: string, idx: number): string =>
        str?.split("@")[idx];

    addBank(): void {
        this._dialog
            .open(PathologyEditComponent, {
                data: {
                    title: "BANK",
                    type: "external",
                    icon: "plus",
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                switchMap((data: any) => {
                    if (data) return this._shared.createBank(data);
                    else return of(null);
                }),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((_newBank) => {
                if (_newBank && _newBank.id) {
                    this.banks.push(_newBank);
                    this.paymentForm.get("bankId").patchValue(_newBank.id);
                    this._snack.open("Nouvelle Banque enregistrée avec succès!", "Ok", {
                        duration: 2000,
                    });
                }
            });
    }

    private _setupForms(): void {
        this.patientForm = this._fb.group(
            assign(new PatientDTO(), {
                firstName: ["", Validators.required],
                lastName: ["", Validators.required],
                email: ["", Validators.email],
                genderId: ["", Validators.required],
                phone: [
                    "",
                    [Validators.maxLength(16), Validators.pattern(new RegExp("\\d"))],
                ],
            })
        );
        this.patientForm
            .get("debiter")
            .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((value) => {
                this.convType = value;

                if (value !== "PATIENT") {
                    this._shared
                        .getOrganismsList()
                        .pipe(takeUntilDestroyed(this.#destroyRef))
                        .subscribe(
                            (_organisms: Hl7IdName[]) => (this.organisms = _organisms)
                        );

                    this._shared
                        .getTariffs()
                        .pipe(takeUntilDestroyed(this.#destroyRef))
                        .subscribe(
                            (value) =>
                                (this.tariffs = value.map((it) => {
                                    const arr = it.split("@");
                                    return {
                                        id: arr[1],
                                        name: arr[0],
                                    };
                                }))
                        );
                }
            });

        this.paymentForm = this._fb.group(
            assign(new PaymentDTO(), {
                enteredAmount: new FormControl(0, {updateOn: "submit"}),
                paymentDispense: new FormControl(false, {updateOn: "submit"}),
                discount: new FormControl(0, {updateOn: "submit"}),
                paymentMethodId: new FormControl(1, {updateOn: "submit"}),
                bankId: new FormControl(1, {updateOn: "submit"}),
                reference: new FormControl("", {updateOn: "submit"}),
                payer: new FormControl("", {updateOn: "submit"}),
                otherPayer: new FormControl("", {updateOn: "submit"}),
                payerID: new FormControl("", {updateOn: "submit"}),
            })
        );

        this.addressForm = this._fb.group(new AddressDTO());
        this.ageForm = this._fb.group({
            years: 0,
            months: 0,
            days: 0,
        });
        this.insuranceForm = this._fb.group(new InsuranceDTO());

        this.medicalHistoryForm = this._fb.group(new MedicalHistoryDTO());

        this.examsForm = this._fb.group({
            exams: this._fb.array([this._createExamForm()]),
        });

        this._exams = this.examsForm.get("exams") as FormArray;

        const _modelObservable = [
            this.examsForm.valueChanges,
            this.insuranceForm.valueChanges,
            this.paymentForm.valueChanges,
        ];

        combineLatest(_modelObservable)
            .pipe(
                debounceTime(400),
                tap((data) => this._fetchConventionExceptions(data)),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe(this._handlePaymentDetails.bind(this));

        // Firing the following events is needed for combineLatest operator when no convention is selected.
        this.insuranceForm.patchValue(new InsuranceDTO());
        this.paymentForm.patchValue(new PaymentDTO());
    }

    private setDefaultValues(): void {
        const paymentMethod = this.paymentMethods.find(
            (method: any) => method.value === this.defaultValues.defaultPaymentMethod
        );
        const confidentialityId =
            this.confidentialities.find(
                (it: any) => it.value === this.defaultValues.defaultConfidentiality
            )?.id || 1;
        const patientClassId =
            this.patientClasses.find(
                (it: any) => it.value === this.defaultValues.defaultPatientClass
            )?.id || 3;
        const genderId =
            this.genders.find(
                (it: any) => it.value === this.defaultValues.defaultGender
            )?.id || 13;
        const titleId = this.titles.find(
            (it: any) => it.value === this.defaultValues.defaultTitle
        )?.id;
        const debiter = this.defaultValues.defaultPaymentModality;

        const technicianId = this.defaultValues.defaultTechnician;
        const performingPhysicianId = this.defaultValues.defaultPerformingPhysician;

        this.patientForm.patchValue({
            confidentialityId,
            titleId,
            patientClassId,
            genderId,
            debiter,
        });
        this.paymentForm.get("paymentMethodId").patchValue(paymentMethod?.id);
        this.cityControl.patchValue(this.defaultValues.defaultCity);
        this.addressForm
            .get("country")
            .patchValue(this.defaultValues.defaultCountry);

        const formGroups = this.examsForm.get("exams")["controls"] as FormGroup[];
        formGroups.forEach((fg) => {
            fg.patchValue({
                performingPhysicianId,
                technicianId,
            });
        });
    }

    private _fetchConventionExceptions(data: any): void {
        if (data && data.length > 2) {
            const insurance: InsuranceDTO = data[1];
            const conventionId = this.organismConventions.find(
                (it) => it.conventionName === insurance.conventionName
            )?.conventionId;
            if (conventionId)
                this._shared
                    .getConventionException(conventionId)
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((_exceptions) => {
                        this.exceptions = _exceptions.map((it) => {
                            const arr = it.split("@");
                            return {
                                procedureCode: arr[0]?.trim(),
                                patientPart: arr[1],
                                organismPart: arr[2],
                            };
                        });
                    });
        }
    }

    private _handlePaymentDetails(examsChange: any): void {
        const [{exams}, insurance, payment] = examsChange;

        // Update exams amounts
        const _updatedExams = exams.map((_: any, idx: number) =>
            this._updateExamAmounts(exams[idx], insurance, idx)
        );

        // Update payment
        this._buildPaymentItems(_updatedExams, payment);

        // Update exam form's amounts
        const formGroups = this.examsForm.get("exams")["controls"] as FormGroup[];
        formGroups.forEach((fg, idx) => {
            const exam = _updatedExams[idx];
            fg.patchValue(
                {
                    totalAmount: exam.totalAmount,
                    discount: exam.discount,
                    percentageDiscount: exam.percentageDiscount,
                },
                {emitEvent: false}
            );
        });
    }

    private _updateExamAmounts(
        exam: ImagingExamDTO,
        insurance: InsuranceDTO,
        examIndex: number
    ): ImagingExamDTO {
        const convention = this.organismConventions.find(
            (it) => it.conventionName === insurance.conventionName
        );

        if (convention) {
            let patientPercentage = convention.patientPart;
            let organismPercentage = convention.organismPart;

            const examException = this.exceptions.find(
                (it) =>
                    this._selectedProcedures[examIndex]?.code?.trim() ===
                    it.procedureCode?.trim()
            );
            if (examException) {
                patientPercentage = examException.patientPart;
                organismPercentage = examException.organismPart;
            }

            exam.patientPart = patientPercentage;
            exam.organismPart = organismPercentage;
        }

        return exam;
    }

    private _buildPaymentItems(
        exams: ImagingExamDTO[],
        payment: PaymentDTO
    ): void {
        this.paymentItems = exams.map((exam, idx) => {
            const procedureCode = this._selectedProcedures[idx];

            const organismPartPrice = +(
                (exam.totalAmount * exam.organismPart) /
                100
            ).toFixed(2);
            const patientPartPrice = +(exam.totalAmount - organismPartPrice).toFixed(
                2
            );

            return {
                accessionNumber: exam.accessionNumber,
                procedureCode: procedureCode?.description,
                price: +exam.totalAmount.toFixed(2),
                patientPartPrice,
                organismPartPrice,
                discount: exam.discount,
                code: procedureCode?.code,
            };
        });

        const baseAmount = +this.paymentItems
            .map((it) => it.price)
            .reduce((_sum, _amount) => _sum + _amount, 0)
            .toFixed(2);
        const totalLinearDiscounts = +this.paymentItems
            .map((it) => it.discount)
            .reduce((_td, _d) => _td + _d, 0)
            .toFixed(2);
        const totalPatientPart = +this.paymentItems
            .map((it) => it.patientPartPrice)
            .reduce((_td, _d) => _td + _d, 0)
            .toFixed(2);
        const totalOrganismPart = +this.paymentItems
            .map((it) => it.organismPartPrice)
            .reduce((_td, _d) => _td + _d, 0)
            .toFixed(2);

        const due = +(baseAmount - totalLinearDiscounts).toFixed(2);
        payment.baseAmount = baseAmount;
        payment.due = due;
        payment.enteredAmount = totalPatientPart - totalLinearDiscounts;
        payment.totalAfterDiscount = +(due - payment.discount).toFixed(2);
        payment.organismPart = totalOrganismPart;
        payment.patientPart = +(totalPatientPart - totalLinearDiscounts).toFixed(2);

        if (payment.totalAfterDiscount >= payment.enteredAmount) {
            this.leftAmount = +(
                payment.totalAfterDiscount - payment.enteredAmount
            ).toFixed(2);
        } else {
            this.leftAmount = 0;
            payment.enteredAmount = payment.totalAfterDiscount;
        }

        this.paymentForm.patchValue(payment, {emitEvent: false});

        // Make inputs read only
        this.paymentForm.get("due").disable({emitEvent: false});
        this.paymentForm.get("totalAfterDiscount").disable({emitEvent: false});
        this.paymentForm.get("patientPart").disable({emitEvent: false});
        this.paymentForm.get("organismPart").disable({emitEvent: false});
    }

    private _createExamForm(): FormGroup {
        return this._fb.group(
            assign(new ImagingExamDTO(), {
                procedureCodeId: [null, Validators.required],
                aetId: [null, Validators.required],
                date: [moment(), Validators.required],
                time: [moment().format("HH:mm"), Validators.required],
                duration: [10, Validators.required],
            })
        );
    }

    addItem(skipIncrementAccessionNumber: boolean = false): FormGroup {
        this._exams = this.examsForm.get("exams") as FormArray;
        const physician = this.referringPhysicianControl[0].value;
        this.procedureCodeControl.push(new FormControl());
        this.templateModelCtrl.push(new FormControl());
        this.referringPhysicianControl.push(new FormControl(physician));

        const exams = this._exams["controls"] as any[];
        const l = exams.length;

        const date = exams[l - 1].get("date").value;
        const time = exams[l - 1].get("time").value;
        const duration = 0; //exams[l - 1].get('duration').value;

        const exForm = this._createExamForm();

        exForm.patchValue({
            priorityId: this.priorities[2]?.id,
            performingPhysicianId: this.performingPhysicians[0]?.id,
            date: date,
            time: moment(time, "HH:mm").add(duration, "m").format("HH:mm"),
            accessionNumber: "",
        });

        if (!skipIncrementAccessionNumber) this._incrementAccessionNumber(exForm);

        this.procedureCodeControl[l].valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._shared.queryProcedureCodes(10, 0, "code", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredProcedures[l] = data));

        this.procedureCodeControl[l].patchValue("");

        this.referringPhysicianControl[l].valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._shared.queryReferringPhysicians(10, 0, "lastName", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredReferringPhysicians[l] = data));

        this.templateModelCtrl[l].valueChanges
            .pipe(
                startWith(""),
                switchMap((query) =>
                    this._setting.queryTemplateModels(10, 0, "name", "asc", query)
                ),
                map((data) => data["content"]),
                catchError(() => of([])),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((data) => (this.filteredTemplateModels[l] = data));
        this.templateModelCtrl[l].patchValue("");

        this._exams.push(exForm);
        return exForm;
    }

    private _incrementAccessionNumber(examFG?: FormGroup): any {
        if (
            this._accessionNumber?.length > 2 &&
            this.generalSetting.accessionNumberPrefix
        ) {
            let anSuffix = +this._accessionNumber.replace(
                new RegExp(this.generalSetting.accessionNumberPrefix),
                ""
            );
            this._accessionNumber = `${
                this.generalSetting.accessionNumberPrefix
            }${++anSuffix}`;
            examFG?.patchValue({accessionNumber: this._accessionNumber});
        } else {
            this._shared
                .generateAccessionNumber()
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe((res) => {
                    this._accessionNumber = res.id;
                    examFG?.patchValue({
                        accessionNumber: this._accessionNumber,
                    });
                });
        }
    }

    removeItem(idx: number): void {
        this._exams.removeAt(idx);
        this.procedureCodeControl.splice(idx, 1);
    }

    selectPatient(patient?: PatientDTO): void {
        if (patient) {
            this.patientSelected = true;
            this.patientID = patient.externalPatientID;

            this.fileElements = this._fileService.getPatientDocuments(
                this.patientID,
                "root"
            );

            if (patient.addressId)
                this._patientService
                    .getAddressById(patient.addressId)
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((address: AddressDTO) => {
                        this.addressForm.patchValue(address);
                        this.cityControl.patchValue(address?.city);
                        this.postalCodeControl.patchValue(address?.postalCode);
                    });

            patient.phone = StringUtils.keepDigitsOnly(patient.phone);

            this.patientForm.patchValue(patient);

            if (patient.insuranceId)
                this._patientService
                    .getInsuranceById(patient.insuranceId)
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((insurance: InsuranceDTO) => {
                        this.insuranceForm.patchValue(insurance);
                        if (insurance.conventionName)
                            this._findConventions(insurance.organismId);
                    });

            if (patient.consultingDoctorId)
                this._shared
                    .getReferringPhysicianById(patient.consultingDoctorId)
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((doctor: PhysicianDTO) => {
                        if (doctor) {
                            this.referringPhysicianControl.forEach((fc: FormControl) =>
                                fc.patchValue(doctor.fullName)
                            );

                            const formGroups = this.examsForm.get("exams")[
                                "controls"
                                ] as FormGroup[];
                            formGroups.forEach((_fg) => {
                                _fg.patchValue({
                                    referringPhysicianId: patient.consultingDoctorId,
                                });
                            });
                        }
                    });

            if (!patient.externalPatientID) this._generatePatientID();

            this.changeAge();
        }
    }

    changeDate(): void {
        const {years, months} = this.ageForm.getRawValue();

        const dateOfBirth = moment()
            .subtract(years, "years")
            .subtract(months, "months");

        this.patientForm.get("dateOfBirth").patchValue(dateOfBirth);
    }

    changeAge(): void {
        const value = moment(this.patientForm.get("dateOfBirth").value || moment());

        const diffDuration = moment.duration(moment().diff(value));
        const years = diffDuration.years();
        const months = diffDuration.months();
        const days = diffDuration.days();

        this.ageForm.patchValue({years, months, days});
    }

    findPatient(): void {
        const {firstName, lastName} = this.patientForm.value;

        this._dialog
            .open(PatientListComponent, {
                width: "900px",
                data: {firstName, lastName},
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(this.selectPatient.bind(this));
    }

    enterPaidAmount(event: any): void {
        const _payment = this.paymentForm.getRawValue();
        const _enteredAmount = +event.target.value;

        this.paymentForm
            .get("enteredAmount")
            .patchValue(_enteredAmount, {emitEvent: false});

        this.leftAmount = +(_payment.totalAfterDiscount - _enteredAmount).toFixed(
            2
        );
    }

    enterReference(event: any): void {
        this.paymentForm
            .get("reference")
            .patchValue(event.target.value, {emitEvent: false});
    }

    enterPayerName(event: any): void {
        this.paymentForm
            .get("otherPayer")
            .patchValue(event.target.value, {emitEvent: false});
    }

    enterPayerID(event: any): void {
        this.paymentForm
            .get("payerID")
            .patchValue(event.target.value, {emitEvent: false});
    }

    selectMethod(event: any): void {
        this.paymentForm
            .get("paymentMethodId")
            .patchValue(event.value, {emitEvent: false});
    }

    selectBank(event: any): void {
        this.paymentForm
            .get("bankId")
            .patchValue(event.value, {emitEvent: false});
    }

    changePayer(event: any): void {
        this.payerFormControl.patchValue(event.value);
        this.paymentForm.get("payer").patchValue(event.value, {emitEvent: false});
    }

    enterDiscount(event: any): void {
        let discount = +event.target.value;

        const _payment = this.paymentForm.getRawValue();

        if (discount > _payment.totalAfterDiscount)
            discount = _payment.totalAfterDiscount;

        const _totalAfterDiscount = +(_payment.due - discount).toFixed(2);
        let _enteredAmount = +(_totalAfterDiscount - _payment.organismPart).toFixed(
            2
        );
        if (_enteredAmount < 0) _enteredAmount = 0;

        this.paymentForm
            .get("enteredAmount")
            .patchValue(_enteredAmount, {emitEvent: false});
        this.paymentForm
            .get("totalAfterDiscount")
            .patchValue(_totalAfterDiscount, {emitEvent: false});
        this.paymentForm
            .get("discount")
            .patchValue(discount.toFixed(2), {emitEvent: false});

        this.leftAmount = +(_totalAfterDiscount - _enteredAmount).toFixed(2);
        if (this.leftAmount < 0) this.leftAmount = 0;
    }

    save(): void {
        this.saving = true;

        const method = this.data?.examDetails ? "updateExam" : "createNewExam";

        this._scheduler[method](this._dataToSave)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((response) => {
                this.examSaved = !!response;
                this.saving = false;

                this._snack.open(response ? "Created successfully" : "Error", "", {
                    duration: 1000,
                });
            });
    }

    makeExempt(toggleEvent: MatSlideToggleChange): void {
        if (toggleEvent.checked)
            this.paymentForm.patchValue({
                paymentStatus: "EXEMPT",
                paymentDispense: true,
            });
        for (const key in this.paymentForm.controls)
            if (!["paymentDispense", "locked"].includes(key)) {
                toggleEvent.checked
                    ? this.paymentForm.controls[key].disable({
                        emitEvent: false,
                    })
                    : this.paymentForm.controls[key].enable();
            }
    }

    public printTicket(callback?: () => void): void {
        this.saving = true;
        const {patient, imagingExams} = this._dataToSave;

        const procedureCodes = imagingExams
            .map((_, idx) => this._selectedProcedures[idx]?.description)
            .join(", ");
        const accessionNumbers = imagingExams
            .map((e) => e.accessionNumber)
            .join(" ");
        const physician = this.referringPhysicianControl[0].value;
        const patientID = patient.externalPatientID;
        const patientName = patient.lastName + " " + patient.firstName;
        const date = moment(imagingExams[0].date).format("DD/MM/YYYY HH:mm");
        const gender =
            this.genders.find((it) => it.id === patient.genderId)?.value ?? "";
        const {years, months} = this.ageForm.getRawValue();
        let age = `${years}ans`;
        if (months > 0 && years < 10) age += ` ${months}mois`;

        const printable = new ThermalPrintModel(
            patientID,
            patientName,
            date,
            physician,
            accessionNumbers,
            procedureCodes,
            gender,
            age,
            patient.phone
        );

        if (this.generalSetting.ticketPrintMode === "CHROME")
            this._scheduler
                .printTicket(printable)
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe(() => {
                    if (callback) callback();
                });
        else {
            this._scheduler
                .printCupsTicket(printable)
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe((ok) => {
                    if (ok["status"] !== "ok") console.log("Cannot print the ticket");
                    else {
                        this._snack.open("Printing ticket ...", "", {
                            duration: 3000,
                        });
                        if (callback) callback();
                    }
                });
        }
    }

    printAndSave = () => this.printTicket(() => this.save());

    cancel(): void {
        this.saving = false;
        if (!this.patientSelected && !this.examSaved)
            this._scheduler
                .deleteFiles(this.patientID)
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe();
        if (this.examSaved) this._dialogRef.close(true);
        else this._dialogRef.close({isrId: this.savedIsrIdFromPacs});
    }

    private get _dataToSave(): ExamDetailDTO {
        const imagingExams = this._imagingExams;
        const insurance = this.insuranceForm.getRawValue();
        if (this.selectedConventionId)
            insurance.conventionId = this.selectedConventionId;
        const payment = this.paymentForm.getRawValue();
        // if (this.paymentID) payment.paymentID = this.paymentID;
        return new ExamDetailDTO(
            this.patientForm.getRawValue(),
            this.addressForm.getRawValue(),
            insurance,
            imagingExams,
            payment,
            this.medicalHistoryForm.getRawValue(),
            this.placerOrderNumber,
            this.visitNumber
        );
    }

    private get _imagingExams(): ImagingExamDTO[] {
        const _exams = this._exams.controls.map((ctl) => ctl.getRawValue());
        const _accessionNumbers = [
            ..._exams.map((it) => it.accessionNumber),
            ...this.accessionNumbers,
        ];
        return _exams.map((exam) => {
            exam.tariffId = this.tariffId;
            exam.admissionNumber = this.admissionNumber;
            exam.attachedAccessionNumbers = xor(_accessionNumbers, [
                exam.accessionNumber,
            ]).join(",");
            return exam;
        });
    }

    private _setCardData(card_data: any): void {
        const dob = card_data["date_of_birth"]
            .trim()
            .split(" ")
            .filter((it) => it != "");
        const d = dob[0],
            m = month_from_str(dob[1]),
            y = dob[2];

        this.patientForm.patchValue({
            firstName: card_data["firstnames"],
            lastName: card_data["surname"],
            cin: card_data["card_number"],
            nationalNumber: card_data["national_number"],
            dateOfBirth: new Date(y, m - 1, d, 10, 10, 10),
            genderId: this.genders.find((it) => it.value === card_data["gender"])?.id,
        });

        this.addressForm.patchValue({
            street: card_data["address_street_and_number"],
            province: "",
            city: card_data["address_municipality"],
            country: "Belgique",
            postalCode: card_data["address_zip"],
        });

        this.postalCodeControl.patchValue(card_data["address_zip"]);
        this.cityControl.patchValue(card_data["address_municipality"]);

        this.changeAge();
    }

    scanIDCard(): void {
        if (this.generalSetting.scannerIdUrl?.startsWith("beid")) {
            this.ready = false;

            if (isDevMode() || this.generalSetting.scannerIdUrl?.includes("demo")) {
                setTimeout(() => {
                    const eid_data = StringUtils.BELGIUM_DUMMY_EID_CARD;
                    this.ready = true;
                    this._setCardData(eid_data);
                }, 2500);
            } else {
                this._scheduler.scanIdCard.next(true);
                this._scheduler.beidCardData
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((data) => {
                        const card_data = JSON.parse(data);
                        this.ready = true;
                        if (card_data["success"]) {
                            this._setCardData(card_data);
                        } else {
                            this._snack.open(card_data["message"], "", {
                                duration: 2000,
                            });
                        }
                    });
            }
        } else {
            const data$ = fromFetch(this.generalSetting.scannerIdUrl).pipe(
                switchMap((response) => {
                    if (response.ok) return response.json();
                    else
                        return of({
                            error: true,
                            message: `Error ${response.status}`,
                        });
                }),
                catchError((err) => {
                    console.error(err);
                    return of({error: true, message: err.message});
                })
            );

            const card = {};
            data$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
                next: (res) => {
                    if (res && res.documento) {
                        const doc = res.documento;
                        assign(card, {
                            firstName: doc.nombre,
                            lastName: doc.apellido1,
                            cin: doc.numero_documento,
                            dob: doc.fecha_nacimiento
                                ? moment(doc.fecha_nacimiento, "DDMMYYYY")
                                : null,
                            address: doc.localidad_nacimiento,
                            sex: doc.sexo,
                            country: doc.pais,
                            profilePicture: doc.fichero_fotografia,
                        });
                    }
                },
                complete: () => {
                    this.patientForm.patchValue({
                        firstName: get(card, "firstName", ""),
                        lastName: get(card, "lastName", ""),
                        cin: get(card, "cin", ""),
                    });

                    this.patientForm.patchValue({
                        dateOfBirth: get(card, "dob"),
                        genderId: this.genders.find(
                            (it: any) => it.value === get(card, "sex")
                        )?.id,
                    });

                    this.changeAge();
                },
            });
        }
    }

    printPaymentReceipt(): void {
        if (this.generalSetting.receiptPrintMode === "CHROME") {
            const _snackBarRef = this._snack.open("Impression en cours...", "", {
                duration: 10000,
            });
            this._scheduler
                .printPaymentReceipt(this.paymentForm.getRawValue())
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe((_) => _snackBarRef.dismiss());
        } else {
            this._scheduler
                .printCupsPaymentReceipt(this.paymentForm.getRawValue())
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe((ok) => {
                    if (ok["status"] !== "ok") alert("Cannot print the receipt");
                    else
                        this._snack.open("Printing receipt ...", "", {
                            duration: 3000,
                        });
                });
        }
    }

    generateEFactUrl(): void {
        this._scheduler
            .generateEfactUrl(
                this._imagingExams.map((it) => it.accessionNumber).join("@")
            )
            .pipe(first(), takeUntilDestroyed(this.#destroyRef))
            .subscribe((efact) => open(efact.url));
    }

    /**
     * Handle Attached documents
     *
     */
    updateFileElementQuery(): void {
        this.fileElements = this._fileService.getPatientDocuments(
            this.patientID,
            this.currentRoot ? this.currentRoot.uuid : "root"
        );
    }

    navigateUp(): void {
        if (this.currentRoot && this.currentRoot.parent === "root") {
            this.currentRoot = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        } else {
            this.currentRoot = this._fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    }

    navigateToFolder(element: FileElement): void {
        this.currentRoot = element;
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    }

    addFolder(folder: { name: string }): void {
        const file: FileElement = {
            folder: true,
            patientID: this.patientID,
            name: folder.name,
            parent: this.currentRoot ? this.currentRoot.uuid : "root",
        };

        this._fileService
            .createFile(file)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((res: FileElement) => {
                this.updateFileElementQuery();
            });
    }

    moveElement(event: { element: FileElement; moveTo: FileElement }): void {
        const elt = event.element;
        _set(elt, "parent", event.moveTo.uuid);

        this._fileService
            .updateFile(elt)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((res) => {
                this.updateFileElementQuery();
            });
    }

    afterUpload(event: any): void {
        if (event) this.updateFileElementQuery();
    }

    renameElement(element: FileElement): void {
        _set(element, "name", element.name);

        this._fileService
            .updateFile(element)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(() => {
                this.updateFileElementQuery();
            });
    }

    removeElement(element: FileElement): void {
        this._fileService
            .deleteFile(element)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((ok) => {
                if (ok) this.updateFileElementQuery();
            });
    }

    pushToPath(path: string, folderName: string): string {
        let p = path ? path : "";
        p += `${folderName}/`;
        return p;
    }

    popFromPath(path: string): string {
        let p = path ? path : "";
        const split = p.split("/");
        split.splice(split.length - 2, 1);
        p = split.join("/");
        return p;
    }

    private _selectActualExamDetails(examDetails: ExamDetailDTO): void {
        const {
            patient,
            patientAddress,
            imagingExams,
            payment,
            insurance,
            medicalHistory,
        } = examDetails;

        this.patientForm.patchValue(patient);
        this.addressForm.patchValue(patientAddress);
        this.medicalHistoryForm.patchValue(medicalHistory);
        this.paymentForm.patchValue(payment);
        this.insuranceForm.patchValue(insurance);
        this._patchExamsArray(imagingExams);
    }

    private _patchExamsArray(imagingExams: ImagingExamDTO[]): void {
        if (imagingExams.length > 0) {
            const formArray = this.examsForm.controls["exams"] as FormArray;
            for (let j = 0; j < imagingExams.length; j++) {
                const imagingExam = imagingExams[j];

                this.filteredAets[j] = this.aets;

                forkJoin([
                    this._shared.getProcedureCodeById(imagingExam.procedureCodeId),
                    this._shared.getReferringPhysicianById(
                        imagingExam.referringPhysicianId
                    ),
                    this._shared.getTemplateModelById(imagingExam.templateModelId),
                ])
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((data) => {
                        const [procedure, physician, template] = data;

                        this.procedureCodeControl[j].patchValue(procedure?.code);
                        this._selectedProcedures[j] = procedure;

                        this.referringPhysicianControl[j].patchValue(physician?.fullName);
                        this.templateModelCtrl[j].patchValue(template?.name);

                        formArray.at(j).patchValue(imagingExam);
                        if (j < imagingExams.length - 1) this.addItem(true);
                    });
            }
        }
    }

    onChangeConvention(event: MatSelectChange) {
        const conventionName = event.value;
        if (conventionName)
            this.selectedConventionId = this.organismConventions.find(
                (it) => it.conventionName === conventionName
            )?.conventionId;
    }
}
